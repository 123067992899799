"use client";

import React, { CSSProperties, Suspense, useCallback, useMemo } from "react";
import styles from "./product-card.module.scss";
import cx from "classnames";
import { api, CatalogProductShortDto } from "shared/api";
import type { LinkProps } from "next/link";
import Link from "next/link";
import { ProductCardImages as Images } from "./images";
import { ProductCardPrice as Price } from "./price";
import { IconBolt } from "@tabler/icons-react";
import { ProductCardSplit as Spit } from "./split";
import { productToItem } from "entity/analytics/converters/product-to-item";
import { selectItem } from "entity/analytics/events/select-item";
import { FavoriteButton } from "entity/favorite";
import { defaultDeliveryTime } from "features/product-delivery-time-selector/default-delivery-time";
import { pluralize } from "shared/lib";
interface IProductCard {
  data: CatalogProductShortDto;
  targetBlank?: boolean;
  list?: {
    id: string;
    name: string;
  };
  previewImagesCount?: number;
  query?: {
    collectionSlug?: string;
  };
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
  linkProps?: Omit<LinkProps, "href">;
  prefetch?: boolean;
}
export const ProductCard = (props: IProductCard) => {
  const images = useMemo(() => props.previewImagesCount ? props.data.images?.slice(0, props.previewImagesCount) : props.data.images, [props.previewImagesCount, props.data.images]);
  const discountFetch = api.customerDiscount.useCustomerDiscountActive();
  function compareDates(fromDate: string, futureDate: Date) {
    const currentDate = new Date(fromDate);
    const targetDate = new Date(futureDate);
    return targetDate.getTime() >= currentDate.getTime();
  }
  const discountPriceDataBySPU = Number(discountFetch.data?.filter(item => item.spuId === props.data?.spuId).filter(item => compareDates(new Date().toUTCString(), item.activeTo))[0]?.amount ?? 0);
  const discountPriceDataGlobal = Number(discountFetch.data?.filter(item => item.spuId === undefined).filter(item => compareDates(new Date().toUTCString(), item.activeTo))[0]?.amount ?? 0);
  const discountPriceData = discountPriceDataBySPU === 0 ? discountPriceDataGlobal : discountPriceDataBySPU;
  const data = {
    ...props.data,
    price: props.data.price === 0 ? 0 : props.data.price - discountPriceData,
    priceV2: {
      price: props.data.priceV2.price === 0 ? 0 : props.data.priceV2.price - discountPriceData,
      priceWithExpress: props.data.priceV2.priceWithExpress === 0 ? 0 : props.data.priceV2.priceWithExpress - discountPriceData,
      priceFromAvailability: props.data.priceV2.priceFromAvailability === 0 ? 0 : props.data.priceV2.priceFromAvailability - discountPriceData,
      previousPriceFromAvailability: props.data.priceV2.previousPriceFromAvailability === 0 ? 0 : props.data.priceV2.previousPriceFromAvailability,
      priceWithExpressWithoutDiscount: props.data.priceV2.priceWithExpressWithoutDiscount,
      priceWithoutDiscount: props.data.priceV2.priceWithoutDiscount,
      discountFromAvailability: discountPriceData !== 0 || props.data.priceV2.discountFromAvailability,
      discount: discountPriceData !== 0 || props.data.priceV2.discount
    },
    split: props.data.split !== undefined ? {
      first: props.data.split.first - discountPriceData / 2,
      second: props.data.split.second - discountPriceData / 2
    } : undefined
  };
  const poizonDiscount = data.fromAvailability ? data.priceV2.priceFromAvailability < data.priceV2.previousPriceFromAvailability : data.priceV2.price < data.priceV2.priceWithoutDiscount;
  const onClick = useCallback(() => {
    if (props.list) {
      selectItem({
        item_list_id: props.list.id,
        item_list_name: props.list.name,
        items: [productToItem(data)]
      });
    }
  }, [data, props.list?.id, props.list?.name]);
  let href = `/product/${data.slug}-${data.spuId}?sku=${data.skuId}`;
  if (props.query?.collectionSlug) {
    href += `&collectionSlug=${props.query?.collectionSlug}`;
  }
  const deliveryTime = props.data.deliveryTime || defaultDeliveryTime;
  return <Link href={href} target={props.targetBlank ? "_blank" : undefined} className={cx(styles.product_card, props.className)} style={props.style} id={props.htmlId} prefetch={false} onClick={onClick} data-sentry-element="Link" data-sentry-component="ProductCard" data-sentry-source-file="product-card.tsx">
      <Images images={images} alt={data.name} data-sentry-element="Images" data-sentry-source-file="product-card.tsx" />
      <Suspense data-sentry-element="Suspense" data-sentry-source-file="product-card.tsx">
        <FavoriteButton product={data} className={styles.favorite} data-sentry-element="FavoriteButton" data-sentry-source-file="product-card.tsx" />
      </Suspense>

      <div className={styles.card_body}>
        <Price sale={poizonDiscount} availability={data.availability} price={data.fromAvailability ? data.priceV2.priceFromAvailability : data.priceV2.price} priceWithoutDiscount={data.fromAvailability ? data.priceV2.previousPriceFromAvailability : data.priceV2.priceWithoutDiscount} data-sentry-element="Price" data-sentry-source-file="product-card.tsx" />
        {data.split && <Spit value={data.split.first} />}

        <div className={styles.name}>{data.name}</div>
        <div className={styles.properties}>
          {data.returnable && !props.data.fromAvailability && <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_15998_40744)">
                <path d="M3.5 2.9165H10.5L12.25 5.83317L7.29167 11.3748C7.25365 11.4136 7.20827 11.4445 7.15818 11.4655C7.1081 11.4866 7.05432 11.4974 7 11.4974C6.94568 11.4974 6.8919 11.4866 6.84181 11.4655C6.79173 11.4445 6.74635 11.4136 6.70833 11.3748L1.75 5.83317L3.5 2.9165Z" stroke="#FF385C" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.83341 6.99997L4.66675 5.71663L5.01675 5.1333" stroke="#FF385C" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_15998_40744">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>}
          <div className={styles.delivery}>
            {props.data.fromAvailability ? <>
                <div className={styles.propertiesAvailable}>
                  <span className={styles.now}>приедет завтра</span>
                  {props.data.size?.eu !== undefined && <span className={styles.size}>{props.data.size?.eu} EU</span>}
                </div>
                <div className={styles.last}>остался последний</div>
              </> : <>
                {pluralize(deliveryTime.min, {
              one: it => `${it} день`,
              few: it => `${it} дня`,
              many: it => `${it} дней`
            })}{" "}
                &middot; <IconBolt stroke={1} size={14} className={styles.icon} />
                {pluralize(deliveryTime.expressMin, {
              one: it => `${it} день`,
              few: it => `${it} дня`,
              many: it => `${it} дней`
            })}
              </>}
          </div>
        </div>
      </div>
    </Link>;
};