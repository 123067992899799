"use client";

import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import styles from "./product-card-images.module.scss";
import { poizonImageLoader } from "shared/api/images/poizon-image-loader";
import { useDebouncedCallback } from "use-debounce";
import { imageLoadError } from "entity/analytics/events/image-load-error";
export interface IProductCardImages {
  images: Array<string>;
  alt?: string;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}
export const ProductCardImages = (props: IProductCardImages) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(0);
  const updateActive = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;
    const activeIndex = [...container.children].findIndex((element, index) => Math.abs(element.getBoundingClientRect().left - container.getBoundingClientRect().left) < 10);
    if (activeIndex >= 0) setActive(activeIndex);
  }, [containerRef.current, setActive]);
  const onScroll = useDebouncedCallback(updateActive, 100);
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    container.addEventListener("scroll", onScroll);
    container.addEventListener("scrollend", updateActive);
    return () => {
      container.removeEventListener("scroll", onScroll);
      container.removeEventListener("scrollend", updateActive);
    };
  }, [updateActive]);
  const indicatorInnerStyle = useMemo(() => {
    if (props.images.length < 3) return {
      transform: `translateX(8px)`
    };
    if (active < 2) return {};
    if (active === props.images.length - 1) return {
      transform: `translateX(${(2 - active) * 8}px)`
    };
    return {
      transform: `translateX(${(1 - active) * 8}px)`
    };
  }, [active, props.images.length]);
  return <div className={cx(styles.wrap, props.className)} style={props.style} id={props.htmlId} data-sentry-component="ProductCardImages" data-sentry-source-file="product-card-images.tsx">
      <div className={styles.scroll} ref={containerRef}>
        {props.images.map((it, key) => {
        const src = poizonImageLoader({
          src: it,
          width: 640
        });
        return <div key={key}>
              <img src={poizonImageLoader({
            src: it,
            width: 640
          })} alt={props.alt || ""} fetchPriority={key === 0 ? "high" : "low"} loading="lazy" decoding="async" onError={() => imageLoadError(src)} />
            </div>;
      })}
      </div>

      {props.images.length > 1 && <div className={styles.dots}>
          <div style={indicatorInnerStyle}>
            {props.images.map((it, key) => <div className={cx(active === key && styles.active)} key={key} />)}
          </div>
        </div>}
    </div>;
};