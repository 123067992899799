import type { ImageLoaderProps, ImageProps } from "next/image"

export const poizonImageLoader = (props: ImageLoaderProps) => {
  return `https://img-opt.unicorngo.ru/?url=${props.src}&w=${props.width}&q=${props.quality || 75}`
}

export const poizonImageLoaderTrim = (props: ImageLoaderProps) => {
  return `https://img-opt.unicorngo.ru/?url=${props.src}&w=${props.width}&q=${props.quality || 75}&trim=1`
}

export const poizonImageLoaderPropsIfNeed = (src: string, trim = false): { loader?: ImageProps["loader"] } => {
  return { loader: trim ? poizonImageLoaderTrim : poizonImageLoader }
}
